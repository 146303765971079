import * as React from 'react'
import Seo from 'components/seo'
import Layout from 'layouts/zh'
import SectionContent from 'components/SectionContent'
import saVersion from 'assets/images/terms/lifecycle_details/sa-version-20240717.png'
import sfVersion from 'assets/images/terms/lifecycle_details/sf-version-20240717.png'
import spsVersion from 'assets/images/terms/lifecycle_details/sps-version-2024-new.png'
import { NavMenu } from '../../solutions/iptv/index.zh'
import * as styles from './index.module.less'

const Lifecycle = () => {
  return (
    <Layout>
      <Seo
        title="产品版本服务支持声明-神策数据"
        description="关于神策数据产品版本服务支持的声明"
        keywords="产品版本服务支持，神策数据"
        saTitle="首页-用户中心（产品版本服务支持声明）"
      />
      <main className={styles.LifeDetail}>
        <div className="title-wrap">
          <h2 className="title show-pc">关于产品版本服务支持的声明</h2>
          <h2 className="title show-mobile">关于产品版本服务支持的声明</h2>
        </div>
        <NavMenu
          menus={[
            {
              url: '/terms/lifecycle.html',
              name: '产品生命周期管理',
            },
            {
              url: '/terms/lifecycle_details.html',
              name: '产品版本服务支持声明',
              isActive: true,
            },
          ]}
        />
        <SectionContent className="content-wrap">
          <h3 className="honorific">尊敬的神策客户：</h3>
          <section className="content">
            <p>感谢您选择神策产品！</p>
            <p>
              我们将严格遵照全球软件生命周期管理规范和标准，在服务周期内持续提供新功能、Bug
              修补程序和安全修复程序等服务。您可以<a href="/terms/lifecycle.html">点击此处</a>
              ，查看关于产品生命周期管理及各版本定义的完整说明。
            </p>
            <p>
              根据全球软件行业的最佳实践和行业标准，EOS（End of Service，停止产品软件更新服务）
              意味着该版本不再提供功能更新、Bug
              修复程序和安全修复程序等方面的支持；相关支持内容也将不再进行更新，包括官方网站的帮助文档和其他联机支持；此外，也将停止针对该版本的个性化开发和技术修复。
            </p>
            <p>
              与此同时，正在服务中的运维和售后答疑等支持将会正常进行。 以下为各版本的 EOS
              时间，神策保留进一步解释的权利。
            </p>
          </section>
        </SectionContent>
        <SectionContent className="version-lifecycle">
          <h3 className="explain">神策分析</h3>
          <p className="table relative">
            <img src={saVersion} alt="" />
            {/* 热点区域  ss-210 临时方案 后续页面整体升级*/}
            {/* <span className={styles.maskLayer} onClick={() => (window.location.href = '/terms/sa-v2_5.html')} /> */}
          </p>
        </SectionContent>
        <SectionContent className="version-lifecycle">
          <h3 className="explain">神策智能运营</h3>
          <p className="table ">
            <img src={sfVersion} alt="神策智能运营" />
          </p>
        </SectionContent>
        <SectionContent className="version-lifecycle">
          <h3 className="explain">神策用户画像</h3>
          <p className="table">
            <img src={spsVersion} alt="" />
          </p>
        </SectionContent>
        <SectionContent className="version-deploy">
          <section className="content">
            <p>
              尽管您现在仍可正常使用神策产品的早期版本；然而，根据软件产品生命周期管理规范、以及相应版本服务和支持的停止，您将不会再收到任何产品相关的安全更新和
              Bug
              修复信息，早期软件版本将可能存在稳定性问题。强烈推荐您升级到所使用神策产品的最新版本，保障产品的高可用性。升级后，我们也会提供相应的培训课程与操作视频，便于您以及团队成员第一时间掌握最新的功能与使用方法。
            </p>
            <p style={{ marginTop: 30 }}>
              如有任何问题，欢迎您在客户对接群联系神策工作人员或联系
              contact@sensorsdata.com，我们将竭诚为您服务。最后，感谢您对神策数据一如既往的支持，希望通过您的参与和我们的主动式服务，能让神策产品为您的业务充分发挥应有的价值！
            </p>
            <h4 className="author">
              <span />
              <span>神策数据产品线委员会</span>
            </h4>
          </section>
        </SectionContent>
      </main>
    </Layout>
  )
}

export default Lifecycle
